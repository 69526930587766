import { useCallback } from 'react'
import toast from 'react-hot-toast'
import { useTranslation } from 'next-i18next'
import { KeyedMutator, MutatorCallback } from 'swr'
import { likePost, unlikePost } from 'modules/community/api/community-api'
import { PostInterface } from 'modules/community/types/post-interface'
import { getPostActions } from 'modules/post/utils/post-utils'

export const useToggleLike = ({
  mutatePosts,
  communityPath,
}: {
  mutatePosts: KeyedMutator<PostInterface[]>
  communityPath: string
}) => {
  const { t } = useTranslation()

  const handleUnlike = async (
    id: number,
    globalMutatePost: (
      mutator: MutatorCallback<PostInterface>,
    ) => Promise<PostInterface | undefined>,
  ) => {
    await unlikePost(id)
    await mutatePosts(
      data =>
        data &&
        data.map(post =>
          post.id === id ? { ...post, isLiked: false, likesCount: post.likesCount - 1 } : post,
        ),
      false,
    )
    globalMutatePost(post => post && { ...post, isLiked: false, likesCount: post.likesCount - 1 })
  }

  const handleLike = async (
    id: number,
    globalMutatePost: (
      mutator: MutatorCallback<PostInterface>,
    ) => Promise<PostInterface | undefined>,
  ) => {
    await likePost(id)
    await mutatePosts(
      data =>
        data &&
        data.map(post =>
          post.id === id ? { ...post, isLiked: true, likesCount: post.likesCount + 1 } : post,
        ),
      false,
    )
    globalMutatePost(post => post && { ...post, isLiked: true, likesCount: post.likesCount + 1 })
  }

  return useCallback(
    ({ id, topic, path, isLiked }: PostInterface) =>
      async () => {
        const { globalMutatePost } = getPostActions({
          communityPath: communityPath,
          topicPath: topic.path,
          postPath: path,
        })

        try {
          if (isLiked) {
            handleUnlike(id, globalMutatePost)
          } else {
            handleLike(id, globalMutatePost)
          }
        } catch (err) {
          toast.error(t('post_details.error.error_like_post'))
        }
      },
    [mutatePosts, t, communityPath],
  )
}
