import React from 'react'
import toast from 'react-hot-toast'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import useSWR from 'swr'
import { likePost, unlikePost } from 'modules/community/api/community-api'
import * as postsApi from 'modules/community/api/posts-api'
import { usePinnedPosts } from 'modules/community/api/use-pinned-posts'
import { ACTIVITY_CLEANUP_PERIOD_DURATION } from 'modules/community/const/post-consts'
import { PostCommentsInfoProvider } from 'modules/community/context/post-comments-info'
import { BanPeriodUnitEnum } from 'modules/community/enums/member-enum'
import useCommentsInfo from 'modules/community/hooks/use-comments-info'
import { PostInterface } from 'modules/community/types/post-interface'
import { getPostActions } from 'modules/post/utils/post-utils'
import { PostPinned } from './post-pinned'

export const PostPinnedList = () => {
  const { t } = useTranslation()
  const router = useRouter()
  const { communityPath } = router.query
  const getPinnedPostsQuery = usePinnedPosts({})
  const { mutate, data: posts = [] } = useSWR<PostInterface[]>('posts')

  const commentsInfoMap = useCommentsInfo({
    postIds: getPinnedPostsQuery.data?.map(item => item.id),
  })

  function deletePost(postId: number) {
    return async () => {
      await postsApi.deletePost(postId)
      const filteredPosts = getPinnedPostsQuery.data?.filter(p => p.id !== postId)
      await getPinnedPostsQuery.mutate(filteredPosts, false)
    }
  }

  function deletePostAndBanUser(postId: number, userId: number) {
    return async (removeRecentActivitiesChecked: boolean) => {
      await postsApi.banUser(
        communityPath as string,
        userId,
        removeRecentActivitiesChecked
          ? {
              deleteRecentActivityOptions: {
                periodUnit: BanPeriodUnitEnum.day,
                periodDuration: ACTIVITY_CLEANUP_PERIOD_DURATION,
              },
            }
          : undefined,
      )
      await postsApi.deletePost(postId)
      if (removeRecentActivitiesChecked) {
        await mutate()
        return
      }
      const filteredPosts = posts.filter(p => p.id !== postId)
      await mutate(filteredPosts, false)
    }
  }

  const handleLikeClick =
    ({ id, topic, path, isLiked }: PostInterface) =>
    async () => {
      const { globalMutatePost } = getPostActions({
        communityPath: communityPath as string,
        topicPath: topic.path,
        postPath: path,
      })
      const isPinnedPost = getPinnedPostsQuery.data?.find(post => post.id === id)
      const mutateTarget = isPinnedPost ? getPinnedPostsQuery.mutate : mutate

      try {
        if (isLiked) {
          await unlikePost(id)
          mutateTarget(
            data =>
              data &&
              data.map(post =>
                post.id === id
                  ? { ...post, isLiked: false, likesCount: post.likesCount - 1 }
                  : post,
              ),
            false,
          )
          globalMutatePost(
            post => post && { ...post, isLiked: false, likesCount: post?.likesCount - 1 },
          )
        } else {
          await likePost(id)
          await mutateTarget(
            data =>
              data &&
              data.map(post =>
                post.id === id ? { ...post, isLiked: true, likesCount: post.likesCount + 1 } : post,
              ),
            false,
          )
          globalMutatePost(
            post => post && { ...post, isLiked: true, likesCount: post?.likesCount + 1 },
          )
        }
      } catch (err) {
        toast.error(t('post_details.error.error_like_post'))
      }
    }

  return (
    <PostCommentsInfoProvider commentsInfoMap={commentsInfoMap}>
      {getPinnedPostsQuery.data?.map(post => (
        <PostPinned
          post={post}
          key={post.id}
          deletePost={deletePost(post.id)}
          deletePostAndBanUser={deletePostAndBanUser(post.id, post.userId)}
          handleLikeClick={handleLikeClick(post)}
        />
      ))}
    </PostCommentsInfoProvider>
  )
}
