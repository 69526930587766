import React from 'react'
import PostAnnounce from 'modules/community/components/post-announce/post-announce'
import { PostInterface } from 'modules/community/types/post-interface'

interface PostAnnouncePinnedProps {
  post: PostInterface
  deletePost: () => Promise<void>
  deletePostAndBanUser: (removeRecentActivitiesChecked: boolean) => Promise<void>
  handleLikeClick?: () => void
}

export const PostPinned = ({
  post,
  deletePost,
  deletePostAndBanUser,
  handleLikeClick,
}: PostAnnouncePinnedProps) => {
  return (
    <PostAnnounce
      isPinnedPost={true}
      post={post}
      key={post.id}
      deletePost={deletePost}
      deletePostAndBanUser={deletePostAndBanUser}
      handleLikeClick={handleLikeClick}
    />
  )
}
